import React from 'react';
import styled from 'styled-components';
import * as helperConstants from '../helpers/constants';

const StyledButton = styled.button`
    display: block;
        width: 90%;
        padding: 7px 15px;
        margin: 10px auto;
        border-radius: 4px;
        border: 1px solid ${helperConstants.brandRed};
        outline: none;
        &.submitButton {
            background: ${helperConstants.brandRed};
            color: ${helperConstants.white};
            font-weight: 600;
        }
`;

export const Button = (props) => {
    return (
        <StyledButton type={props.type} className={props.className}>{props.text}</StyledButton>
    );
};