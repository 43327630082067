const cartWithoutItem = (cart, item) => cart.filter(cartItem => cartItem.id !== item.id);
const itemInCart = (cart, item) => cart.filter(cartItem => cartItem.id === item.id)[0];

const addToCart = (cart, item) => {
    const cartItem = itemInCart(cart, item);
    let extraArray = [];
    if (cartItem !== undefined) {
        if (cartItem.extraResult.length > 0 && item.extra.length !== 0) {
            console.log(item)
            extraArray = cartItem.extraResult;
            extraArray.push(item.extra);
        }
    }

    return cartItem === undefined
        ? [...cartWithoutItem(cart, item), {
            ...item,
            quantity: 1,
            extraResult: (item.extra.length === 0) ? [] : [item.extra]
        }]
        : [...cartWithoutItem(cart, item), {
            ...cartItem, quantity: cartItem.quantity + 1,
            extraResult: (item.extra.id === undefined) ? ((cartItem.extraResult.length === 0) ? [] : cartItem.extraResult) : (cartItem.extraResult.length > 0) ? extraArray : [item.extra]
        }]
};

const addExtraToCart = (cart, item) => {
    const cartItem = itemInCart(cart, item);
    let extraArray = [];
    if (cartItem !== undefined) {
        extraArray = cartItem.extraResult;
        if (extraArray.length < item.quantity)
            extraArray.push(extraArray[0]);
    }

    return cartItem === undefined
        ? [...cartWithoutItem(cart, item), {
            ...item,
            quantity: 1,
            extraResult: (item.extra.length === 0) ? [] : [item.extra]
        }]
        : [...cartWithoutItem(cart, item), {
            ...cartItem, quantity: cartItem.quantity,
            extraResult: (item.extra.id === undefined) ? ((cartItem.extraResult.length === 0) ? [] : cartItem.extraResult) : (cartItem.extraResult.length > 0) ? extraArray : [item.extra]
        }]
};

const removeFromCart = (cart, item) => {
    let extraArray = [];
    extraArray = item.extraResult;
    extraArray.pop();
    return item.quantity === 1
        ? [...cartWithoutItem(cart, item)]
        : [...cartWithoutItem(cart, item), {
            ...item,
            quantity: item.quantity - 1,
            extraResult: (item.extra.length === 1) ? [] : extraArray
        }]
};

const removeExtraFromCart = (cart, item) => {
    let extraArray = [];
    extraArray = item.extraResult;
    extraArray.pop();

    return item.quantity === 1
        ? [...cartWithoutItem(cart, item)]
        : [...cartWithoutItem(cart, item), {
            ...item,
            quantity: item.quantity,
            extraResult: (item.extra.length === 1) ? [] : extraArray
        }]
};

const removeAllFromCart = (cart, item) => {
    return [...cartWithoutItem(cart, item)]
};

const cartReducer = (state = [], action) => {
    switch (action.type) {
        case 'ADD':
            return addToCart(state, action.payload);
        case 'ADD_EXTRA':
            return addExtraToCart(state, action.payload);
        case 'REMOVE':
            return removeFromCart(state, action.payload);
        case 'REMOVE_EXTRA':
            return removeExtraFromCart(state, action.payload);
        case 'REMOVE_ALL':
            return removeAllFromCart(state, action.payload);
        default:
            return state;
    }
};

export default cartReducer;