import { createStore, combineReducers } from 'redux';
import cartReducer from '../features/cart/reducer';
import notifyReducer from '../features/notify/reducer';

const rootReducer = combineReducers({
    cart: cartReducer,
    notify: notifyReducer
});

const store = createStore(
    rootReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;