import React from 'react';
import styled from 'styled-components'
import * as helperConstants from '../helpers/constants';

const LogoStyled =  styled.a`
  cursor: pointer;
  position: relative;
  padding: 15px 0;
  display: inline-block;
  h1 {
    color: ${helperConstants.black};
    span {
     color: ${helperConstants.brandLightRed};
    }
    @media screen and (max-width: 767px) {
     font-size: 1.5rem;
    }
  }
  h2 {
   color: ${helperConstants.white};
   font-family: 'Anton', sans-serif !important;
   font-size: 25px;
   transform: rotate(-25deg);
   position: absolute;
   top: 20px;
   margin-left: 220px;
   text-shadow: -2px 0 ${helperConstants.brandLightRed}, 0 2px ${helperConstants.brandLightRed}, 2px 0 ${helperConstants.brandLightRed}, 0 -2px ${helperConstants.brandLightRed};
   @media screen and (max-width: 767px) {
     font-size: 20px;
     margin-left: 120px;
    }
  }
`;

export const Logo = () => <LogoStyled>
    <h1><span>top</span>Langoše.sk</h1>
    <h2>donáška</h2>
</LogoStyled>;