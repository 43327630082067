import React from 'react';
import styled from 'styled-components';
import * as helperConstants from '../helpers/constants';

const LabelWrapper = styled.div`
    input {
        visibility: hidden;
    }
    label {
        position: relative;
        cursor: pointer;
        &:before {
            content: '';
            width: 18px;
            height: 18px;
            border: 2px solid ${helperConstants.brandRed};
            position: absolute;
            left: -25px;
            top: 2px;
        }
        a {
            color: ${helperConstants.brandRed};
        }
    }
    input:checked ~ label:after {
        content: '';
        width: 6px;
        height: 12px;
        margin-top: -20px;
        margin-left: -19px;
        border: solid #9E2023;
        border-width: 0 3px 3px 0;
        margin-bottom: 8px;
        transform: rotate(45deg);
        display: block;
    }
`;

export const Input = (props) => {
    const inputStyles = {
        display: 'block',
        width: '90%',
        padding: '7px 15px',
        margin: '10px auto',
        borderRadius: '4px',
        border: '1px solid #000000',
        outline: 'none'
    };

    if (props.label) {
        return (
            <LabelWrapper>
            <input type={props.type} value={props.value} name={props.name} placeholder={props.placeholder} checked={props.checked}
                   onChange={props.onChange} id={props.id} /> <label htmlFor={props.id} dangerouslySetInnerHTML={{__html: props.label}} />
            </LabelWrapper>
        );
    }
    return (
        <input type={props.type} value={props.value} name={props.name} placeholder={props.placeholder}
               style={inputStyles} onChange={props.onChange} required="required" />
    );
};