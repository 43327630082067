import React, {useState} from 'react';
import styled from 'styled-components'
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import {Input} from './Input';
import {UserIcon} from './UserIcon';
import {Button} from './Button';
import * as helperConstants from '../helpers/constants';
import {register} from '../services/api/register';
import {login} from '../services/api/login';
import {forgotPassword} from '../services/api/forgotPassword';
import Alert from 'reactstrap/es/Alert';
import {connect} from 'react-redux';

const PopupContainer = styled.div`
    div.register, div.forgotPassword, div.login {
        display: none;
        &.active {
            display: block;
            text-align: center;
            button.showAction {
                text-align: center;
                text-decoration: underline;
                outline: none;
                border: none;
            }
        }
    }
    .closeModal {
        border: none;
        outline: none;
        border-radius: 100%;
        width: 35px;
        height: 35px;
        position: absolute;
        right: -10px;
        top: -10px;
        text-align: center;
        background: ${helperConstants.brandRed};
            color: ${helperConstants.white};
            img {
            width: 15px;
            height: 15px;
            margin-top: -3px;
            }
    }
    padding: 30px;
      h3 {
        text-align: center;
        margin-bottom: 15px;
      }
      
    `;

const LoginPopup = (props) => {
    const [isOpen, setOpen] = useState(false);
    const [loginActive, setLoginActive] = useState(true);
    const [forgotPasswordActive, setForgotPasswordActive] = useState(false);
    const [registerActive, setRegisterActive] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [checked, setChecked] = useState(false);
    const [visible, setVisible] = useState(true);
    const onDismiss = () => setVisible(false);
    return (
        <span>
            <button type="button" onClick={() => setOpen(!isOpen)} className="userAccount"><UserIcon/> Prihlásiť sa</button>
            <Modal isOpen={isOpen}>
                <ModalBody>
                    <PopupContainer>
                        <Alert isOpen={visible} color="success" className="d-none" toggle={onDismiss} closeAriaLabel="X"></Alert>

                        {/*(props.notify) ?*/}
                        {/*        <Alert isOpen={visible} color={props.notify.color} toggle={onDismiss} closeAriaLabel="X">{props.notify.message}</Alert>*/}
                        {/*        : ''*/}


                        <button type="button" className="closeModal" onClick={() => setOpen(false)}>
                            <img src="img/close.svg"/>
                        </button>
                    <div className={(loginActive) ? 'login active' : 'login'}>
                        <h3>Prihlásenie</h3>
                        <form name="login" onSubmit={(e) => { e.preventDefault(); login(e); }}>
                            <Input type="email" placeholder="E-mail" value={email} onChange={(e) => setEmail(e.target.value)} name="email"/>
                            <Input type="password" placeholder="Heslo" value={password} onChange={(e) => setPassword(e.target.value)} name="password"/>
                            <Button type="submit" className="submitButton" text="Prihlásiť sa"/>
                        </form>
                        <button type="button" className="showForgotPassword showAction text-center" onClick={() => { setForgotPasswordActive(true); setLoginActive(false); }}>
                            Zabudnuté heslo?
                        </button>
                        <button type="button" className="showRegister showAction text-center" onClick={() => { setRegisterActive(true); setLoginActive(false); }}>
                            Registrácia
                        </button>
                    </div>
                    <div className={(registerActive) ? 'register active' : 'register'}>
                        <h3>Registrácia</h3>
                        <form name="register" onSubmit={(e) => { e.preventDefault(); register(e); }}>
                            <Input type="email" placeholder="E-mail" value={email} onChange={(e) => setEmail(e.target.value)} name="email"/>
                            <Input type="password" placeholder="Heslo" value={password} onChange={(e) => setPassword(e.target.value)} name="password"/>
                            <Input type="checkbox" name="agree_general_condition" id="agree_general_condition" label="Súhlasím s <a href='/documents/vop.pdf' target='_blank'>obchodnými podmienkami</a>" value={checked} checked={checked} onChange={(e) => setChecked(e.target.checked)} />
                            <Button type="submit" className="submitButton" text="Registrovať"/>
                        </form>
                        <button type="button" className="showLogin showAction text-center" onClick={() => { setRegisterActive(false); setLoginActive(true); }}>
                            Prihlásenie
                        </button>
                    </div>
                    <div className={(forgotPasswordActive) ? 'forgotPassword active' : 'forgotPassword'}>
                        <h3>Zabudnuté heslo</h3>
                        <form name="forgotPassword" onSubmit={(e) => { e.preventDefault(); forgotPassword(e); }}>
                            <Input type="email" placeholder="E-mail" value={email} onChange={(e) => setEmail(e.target.value)} name="email"/>
                            <Button type="submit" className="submitButton" text="Reset hesla"/>
                        </form>
                        <button type="button" className="showLogin showAction text-center" onClick={() => { setForgotPasswordActive(false); setLoginActive(true); }}>
                            Prihlásenie
                        </button>
                    </div>
                        </PopupContainer>
                </ModalBody>
            </Modal>
            </span>
    )
};

function mapStateToProps(state) {
    return {
        notify: state.notify
    }
}

function mapDispatchToProps(dispatch) {
    return {
        addNotify: (notify) => {
            dispatch({type: 'ADD_NOTIFY', payload: notify})

        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPopup);