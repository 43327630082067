import {serializeForm, validateEmail, validateFirstName, validateLastName, validateTelephone} from '../helpers';
import axios from 'axios';
import {orderEndpoint} from '../../endpoints';

export function makeOrder(e) {
    const data = serializeForm(e.target);
    const orderData = JSON.parse(data.orderData);

    console.log(data);
    if (validateFirstName(data.first_name).error !== undefined) {
        return errorAlert(validateFirstName(data.first_name).error);
    }
    if (validateLastName(data.last_name).error !== undefined) {
        return errorAlert(validateLastName(data.last_name).error);
    }
    if (validateEmail(data.email).error !== undefined) {
        return errorAlert(validateEmail(data.email).error);
    }
    if (validateTelephone(data.phone_number).error !== undefined) {
        return errorAlert(validateTelephone(data.phone_number).error);
    }
    if (!data.agree_general_condition) {
        return errorAlert('Pre pokračovanie musíš súhlasiť s obchodnými podmienkami.');
    }
    let productsResult = [];
    orderData.map((o, key) => {
        if (o.extraResult.length !== 0) {
            if (o.extraResult.length !== o.quantity) {
                productsResult.push({
                    id: o.id,
                    count: o.extraResult.length,
                    extra: [o.extraResult[0]]
                });
                productsResult.push({
                    id: o.id,
                    count: o.quantity - o.extraResult.length,
                    extra: []
                });
            } else {
                productsResult.push({
                    id: o.id,
                    count: o.quantity,
                    extra: [o.extraResult[0]]
                });
            }
        } else {
            productsResult.push({
                id: o.id,
                count: o.quantity,
                extra: o.extraResult
            });
        }

    });
    axios.post(orderEndpoint, {
        products: productsResult,
        address: {
            name: "",
            street_name: data.street_name,
            house_number: data.house_number,
            city: data.city,
            postal_code: data.zip,
            delivery_destination_id: data.delivery_destination_id
        },
        user: {
            first_name: data.first_name,
            last_name: data.last_name,
            phone_number: data.phone_number,
            email: data.email
        },
        note: data.note
    })
        .then((response) => {
            document.querySelector('.alert').classList.remove('d-none');
            document.querySelector('.alert').classList.remove('alert-danger');
            document.querySelector('.alert').classList.add('alert-success');
            document.querySelector('.alert').innerHTML = 'Gratulujem! Objednávka bola úspešne odoslaná. Na zadaný email a telefónne číslo ti zašleme potvrdenie. Ďakujeme.';
            setTimeout(() => {
                document.querySelector('.alert').classList.add('d-none');
                window.location.href = '/';
            }, 5000);
        })
        .catch((error) => {
            console.error(error);
            document.querySelector('.alert').classList.remove('d-none');
            document.querySelector('.alert').classList.remove('alert-success');
            document.querySelector('.alert').classList.add('alert-danger');
            document.querySelector('.alert').innerHTML = 'Objednávku sa nepodarilo odoslať :(';
        })
        .finally(() => {

        });
}

function errorAlert(msg) {
    document.querySelector('.alert').classList.remove('d-none');
    document.querySelector('.alert').classList.remove('alert-success');
    document.querySelector('.alert').classList.add('alert-danger');
    document.querySelector('.alert').innerHTML = msg;
    setTimeout(() => {
        document.querySelector('.alert').classList.add('d-none');
    }, 5000);
}