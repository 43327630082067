import React from 'react';
import styled from 'styled-components';
import * as helperConstants from '../../helpers/constants';

const AddToCartButton = styled.button`
    border: 2px solid ${helperConstants.brandRed};
    color: ${helperConstants.brandRed};
    padding: 5px 15px;
    text-align: center;
    font-weight: 600;
    width: 150px;
    outline: none;
    ${helperConstants.animation};
    &:hover {
        background: ${helperConstants.brandRed};
        color: ${helperConstants.white};
    }
    @media screen and (max-width: 991px) {
        float: right;
    }
`;

export default function AddButton(props) {
    return (
        <AddToCartButton onClick={() => props.addToCart(props.product, props.extra)}>
            {
                (props.cartItem) ? 'ešte CHCEM' : 'to CHCEM'
            }
        </AddToCartButton>
    );
}