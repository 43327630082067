import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Homepage from './pages/homepage';
import CartPage from './pages/cartpage';
import AccountPage from './pages/accountpage';
import OrderPage from './pages/orderpage';

const Router = () => (
  <Switch>
      <Route exact path='/' component={Homepage} />
      <Route exact path='/kosik' component={CartPage} />
      <Route exact path='/moj-ucet' component={AccountPage} />
      <Route exact path='/objednavka' component={OrderPage} />
  </Switch>
);

export default Router;