import {serializeForm} from '../helpers';
import axios from 'axios';
import {forgotPasswordEndpoint} from '../../endpoints';

export function forgotPassword(e) {
    const data = serializeForm(e.target);
    axios.post(forgotPasswordEndpoint, data)
        .then((response) => {
            console.log(response);
            document.querySelector('.modal .alert').classList.remove('d-none');
            document.querySelector('.modal .alert').classList.remove('alert-danger');
            document.querySelector('.modal .alert').classList.add('alert-success');
            document.querySelector('.modal .alert').innerHTML = 'Na zadanú emailovú adresu bol zaslaný postup pre zmenu hesla.';
        })
        .catch((error) => {
            console.error(error);
            document.querySelector('.modal .alert').classList.remove('d-none');
            document.querySelector('.modal .alert').classList.remove('alert-success');
            document.querySelector('.modal .alert').classList.add('alert-danger');
            document.querySelector('.modal .alert').innerHTML = 'Zadaný email neexistuje. Zopakuj svoje zadanie, prosím.';
        })
        .finally(() => {

        });
}