import React from 'react';

export const UserIcon = (props) =>
    <svg
        xmlns='http://www.w3.org/2000/svg'
        x='0'
        y='0'
        width='30'
        height='30'
        enableBackground='new 0 0 299.997 299.997'
        version='1.1'
        viewBox='0 0 299.997 299.997'
        xmlSpace='preserve'
    >
        <path d='M149.996 0C67.157 0 .001 67.158.001 149.997c0 82.837 67.156 150 149.995 150s150-67.163 150-150C299.996 67.156 232.835 0 149.996 0zm.457 220.763v-.002H85.465c0-46.856 41.152-46.845 50.284-59.097l1.045-5.587c-12.83-6.502-21.887-22.178-21.887-40.512 0-24.154 15.712-43.738 35.089-43.738s35.089 19.584 35.089 43.738c0 18.178-8.896 33.756-21.555 40.361l1.19 6.349c10.019 11.658 49.802 12.418 49.802 58.488h-64.069z'></path>
    </svg>;