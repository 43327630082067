import React from 'react';
import {Logo} from './Logo';
import {UserIcon} from './UserIcon';
import {Row, Col, Container} from 'reactstrap';
import {CartIcon} from './CartIcon';
import {NavLink} from 'react-router-dom';
import LoginPopup from './LoginPopup';
import styled from 'styled-components';
import Cart from '../features/cart';

const HeaderWrapper = styled.div`
    position: fixed;
    top: 25px;
    height: 120px;
    width: 1140px;
    @media screen and (max-width: 991px) {
           width: 100%;
           height: 50px;
           position: relative;
           top: 0;
        }
    .left {
        width: 30%;
        display: inline-block;
        vertical-align: top;
        @media screen and (max-width: 991px) {
            display: none;
        }
    }
    .right {
        width: 70%;
        display: inline-block;
        text-align: center;
        height: 120px;
        @media screen and (max-width: 991px) {
            text-align: left;
            position: fixed;
            h4 {
                display: inline-block;
                font-size: 16px;
                margin-left: 50px;
            }
            height: 66px;
    margin-left: -15px;
    padding-left: 15px;
    background: white;
    padding-top: 5px;
    padding-bottom: 5px;
    z-index: 9;
        }
        @media screen and (max-width: 400px) {
        h4 {
            font-size: 12px !important;
            }
        }
        .userAccount {
            
        }
        @media screen and (max-width: 991px) {
            width: 100%;
        }
    }
`;

export const Header = () => {
    const accountButton = (localStorage.getItem('token'))
        ? <NavLink to='/moj-ucet' className="userAccount"><UserIcon/></NavLink>
        : <LoginPopup />;
    return (
        <HeaderWrapper>
            <div className="position-relative">
                <div className="left">
                    <Cart/>
                </div>
                <div className="right">
                    <NavLink to='/'><Logo/></NavLink>
                    {/*<NavLink to='/kosik'><CartIcon/></NavLink>*/}
                    {/*{accountButton}*/}
                    <br className="d-none d-sm-none d-md-none d-lg-block" />
                    <h4 className="text-center">0910 740 224</h4>
                </div>
            </div>
        </HeaderWrapper>

    );
}