import React, {Component} from 'react';
import './App.css';
import {NavLink} from 'react-router-dom';
import Router from './Router';
import Container from 'reactstrap/es/Container';
import {Footer} from './components/Footer';
import {Header} from './components/Header';
import Cart from './features/cart';
import {Sidebar} from './components/Sidebar';
import {Main} from './components/Main';

const Navigation = (props) => <nav>
    <ul>
        <li><NavLink to='/'>Home</NavLink></li>
        <li><NavLink to='/cart'>Cart</NavLink></li>
    </ul>
</nav>;

class App extends Component {
    render() {
        return (
            <div>
                <div className='content-wrapper'></div>
                <Container className='page-container'>
                    <Header/>
                    <Main/>
                </Container>
                {/*<Footer/>*/}
            </div>
        );
    }
}

export default App;
