import React from 'react';
import styled from 'styled-components';
import * as helperConstants from '../helpers/constants';
import {calculateClosedDoorsAlert} from '../services/helpers';

const NoticeWrapper = styled.div`
    background: ${helperConstants.brandRed};
    color: ${helperConstants.white};
    width: 99%;
    padding: 30px;
    font-size: 22px;
    text-align: center;
    margin: 0 10px 30px;
    @media screen and (max-width: 991px) {
    margin: 0;
    }
    
`;

export const Notice = () => {
    const openData = calculateClosedDoorsAlert();
    if (!openData.open) {
        return (
            <NoticeWrapper>
                <b>{openData.message}</b>
            </NoticeWrapper>
        );
    }

    return <span />;
};