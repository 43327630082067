import React from 'react';
import styled from 'styled-components';
import Cart from '../features/cart';
import * as helperConstants from '../helpers/constants';


const SidebarWrapper = styled.div`
    display: inline-block;
    width: calc(30% + 40px);
    margin-left: -20px;
    margin-top: 10px;
    .sidebarInterest {
        background-size: cover;
        width: 100%;
        color: ${helperConstants.white};
        height: calc(calc(100vh - 195px) / 4);
        background-position: bottom;
        position: relative;
        div {
            position: absolute;
            width: calc(100% - 40px);
            left: 20px;
            height: calc(100% - 40px);
            top: 18px;
            background: rgba(0, 0, 0, .6);
            color: ${helperConstants.white};
            box-shadow: 0px 0px 20px 26px rgba(0, 0, 0, .6);
            span {
                transform: rotate(-20deg);  
                display: block;
                font-size: 25px;
                font-weight: bold;
                text-align: center;
                margin-top: 70px;  
                @media screen and (max-height: 700px) {
                    margin-top: 30px;
                }
            }
            
        }
        &.first {
            background-image: url('img/mnamky/vyvazene.jpg');
        }
        &.second {
            background-image: url('img/mnamky/cerstve.jpg');
            background-position: center;
        }
        &.third {
            background-image: url('img/mnamky/chutne.jpg');
            background-position: center;
        }
        &.fourth {
            background-image: url('img/mnamky/s-laskou.jpg');
            background-position: 12% 12%;
        }
    }
    @media screen and (max-width: 991px) {
            display: none;
        }
`;

export const Sidebar = (props) => {
    return (
        <SidebarWrapper>
            <div className="sidebarInterest first">
                <div>
                    <span>vyvážené</span>
                </div>
            </div>
            <div className="sidebarInterest second">
                <div>
                    <span>čerstvé</span>
                </div>
            </div>
            <div className="sidebarInterest third">
                <div>
                    <span>chutné</span>
                </div>
            </div>
            <div className="sidebarInterest fourth">
                <div>
                    <span>s láskou</span>
                </div>
            </div>
        </SidebarWrapper>
    )
};