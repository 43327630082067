import React from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import * as helperConstants from '../../helpers/constants';
import {changePriceFormat, shortProductName} from '../../services/helpers';
import {NavLink} from 'react-router-dom';

const CartWrapper = styled.div`
    color: ${helperConstants.black};
    height: 150px;
    background: rgba(255, 255, 255, .8);
    box-shadow: 0px 0px 20px 26px rgba(255, 255, 255, .8);
    h5 {
        padding-top: 15px;
        color: ${helperConstants.brandRed};
    }
    &.active {
        background: rgba(158, 32, 35, .8);
        box-shadow: 0px 0px 20px 26px rgba(158, 32, 35, .8);
        color: ${helperConstants.white};
        h5 {
            padding-top: 0;
            color: ${helperConstants.white};
        }
    }
    .cartProducts {
        display: block;
        height: 80px;
        overflow-y: auto !important;
        overflow-x: hidden;
    }
    a.order {
        display: block;
        width: 150px;
        color: ${helperConstants.brandRed};
        background: ${helperConstants.white};
        text-align: center;
        padding: 5px;
        font-weight: bold;
        text-decoration: none;
        margin: 12px auto 0;
        ${helperConstants.animation};
        &:hover {
            background: ${helperConstants.brandRed};
            color: ${helperConstants.white};
        }
    }
    table {
        width: 100%;
        td, th {
            &.productName {
                font-size: 12px;
            }
            font-size: 12px;
            text-align: center;
            &.quantity {
                button {
                    border: 1px solid ${helperConstants.white};
                    color: ${helperConstants.white};
                    outline: none;
                    height: 22px;
                }
                span {
                    border-top: 1px solid ${helperConstants.white};
                    padding: 1px 5px 3px;
                        height: 21.5px;
                        display: inline-block;
                    border-bottom: 1px solid ${helperConstants.white};
                }
            }
            p {
                margin-bottom: 0;
            }
        }
        td {
            padding-bottom: 5px;
        }
        table {
            width: 100%;
        }
    }
`;

function sort(items) {
    return items.sort((a, b) => a.id - b.id)
}


function Cart(props) {
    return (
        <CartWrapper className={(props.cart.length > 0) ? 'active' : ''}>
            <h5 className="text-center">Košík</h5>
            {
                (props.cart.length > 0) ?
                    <div>
                    <div className="cartProducts">
                    <table>
                        <thead>
                        <tr>
                            <th>#</th>
                            <th colSpan={2}>Položka</th>
                            <th>Počet</th>
                            <th>Cena</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            sort(props.cart).map((item, key) =>

                            {
                                if (item.extraResult.length === undefined) {
                                    item.extraResult = [item.extraResult];
                                }

                                const extraNumber = (item.extraResult.length === 0) ? '' : <p></p>;
                                const extraName = (item.extraResult.length === 0) ? '' : <p>+ {item.extraResult[0].name}</p>;
                                const extraCount = (item.extraResult.length === 0) ? '' : <p><button
                                    onClick={() => props.removeExtraFromCart(item)}
                                >-
                                </button>
                                    <span>
                                    {item.extraResult.length}
                                    </span>
                                    <button
                                        onClick={() => props.addExtraToCart(item)}
                                    >+
                                    </button></p>;
                                const extraPrice = (item.extraResult.length === 0) ? '' : <p>{changePriceFormat(item.extraResult[0].price * item.extraResult.length)} €</p>;

                                return (
                                    <tr title={item.name}>
                                        <td>{key + 1}.{extraNumber}</td>
                                        <td className="productName" colSpan={2}>
                                            {shortProductName(item.name)}{extraName}
                                        </td>
                                        <td className="quantity">
                                            <button
                                                onClick={() => props.removeFromCart(item)}
                                            >-
                                            </button>
                                            <span>
                                    {item.quantity}
                                    </span>
                                            <button
                                                onClick={() => props.addToCart(item)}
                                            >+
                                            </button>
                                            {extraCount}
                                        </td>
                                        <td>{changePriceFormat(item.price * item.quantity)} € {extraPrice}</td>
                                        <td>
                                            {/*<button*/}
                                            {/*    onClick={() => props.removeAllFromCart(item)}*/}
                                            {/*>Remove all from cart*/}
                                            {/*</button>*/}
                                        </td>
                                    </tr>
                                )

                            }


                            )
                        }
                        </tbody>
                    </table> </div>
                        <NavLink to="/objednavka" className="order">Objednaj teraz</NavLink>
                    </div>
                    :
                    <p className="text-center mt-5">Tvoj košík je zatiaľ prázdny :(</p>
            }

        </CartWrapper>
    );
}

function mapStateToProps(state) {
    return {
        cart: state.cart
    }
}

function mapDispatchToProps(dispatch) {
    return {
        addToCart: (item) => {
            dispatch({type: 'ADD', payload: item})

        },
        removeFromCart: (item) => {
            dispatch({type: 'REMOVE', payload: item})
        },
        addExtraToCart: (item) => {
            dispatch({type: 'ADD_EXTRA', payload: item})
        },
        removeExtraFromCart: (item) => {
            dispatch({type: 'REMOVE_EXTRA', payload: item})
        },
        removeAllFromCart: (item) => {
            dispatch({type: 'REMOVE_ALL', payload: item})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Cart);

