import React from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import * as helperConstants from '../helpers/constants';
import {NavLink} from 'react-router-dom';

const MobileCartWrapper = styled.div`
    position: fixed;
    background: ${helperConstants.brandRed};
    color: ${helperConstants.white};
    bottom: 0;
    left: 0;
    width: 100%;
    height: 70px;
    .orderButton {
        background: ${helperConstants.white};
        padding: 5px 10px;
        color: ${helperConstants.brandRed};
        float: right;
        margin-right: 15px;
        font-weight: bold;
        border: none;
        outline: none
        font-size: 18px;
        margin-top: 15px;
    }
    svg {
    margin-top: 15px;
    margin-left: 15px;
        path {
         fill: ${helperConstants.white};
         }
    }
    span {
        margin-top: 20px;
        margin-left: 10px;
        position: absolute;
    }
    @media screen and (min-width: 992px) {
        display: none;
    }
`;

const MobileCart = (props) => {
    let countItems = 0;
    let priceItems = 0;
    if (props.cart.length !== 0) {
        props.cart.map((product, key) => {
            countItems += product.quantity;
        });
        //console.log(props.cart)
    }
    return (
        <MobileCartWrapper>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30px"
                height="30px"
                viewBox="0 -31 512 512"
            >
                <path d="M164.96 300.004h.024c.02 0 .04-.004.059-.004H437a15.003 15.003 0 0014.422-10.879l60-210a15.003 15.003 0 00-2.445-13.152A15.006 15.006 0 00497 60H130.367l-10.722-48.254A15.003 15.003 0 00105 0H15C6.715 0 0 6.715 0 15s6.715 15 15 15h77.969c1.898 8.55 51.312 230.918 54.156 243.71C131.184 280.64 120 296.536 120 315c0 24.812 20.188 45 45 45h272c8.285 0 15-6.715 15-15s-6.715-15-15-15H165c-8.27 0-15-6.73-15-15 0-8.258 6.707-14.977 14.96-14.996zM477.114 90l-51.43 180H177.032l-40-180zm0 0M150 405c0 24.813 20.188 45 45 45s45-20.188 45-45-20.188-45-45-45-45 20.188-45 45zm45-15c8.27 0 15 6.73 15 15s-6.73 15-15 15-15-6.73-15-15 6.73-15 15-15zm0 0M362 405c0 24.813 20.188 45 45 45s45-20.188 45-45-20.188-45-45-45-45 20.188-45 45zm45-15c8.27 0 15 6.73 15 15s-6.73 15-15 15-15-6.73-15-15 6.73-15 15-15zm0 0"></path>
            </svg> <span>( {countItems} ks )</span>
            <NavLink to='/objednavka' className="orderButton">OBJEDNAJ</NavLink>
        </MobileCartWrapper>
    )
}

function mapStateToProps(state) {
    return {
        cart: state.cart
    }
}

function mapDispatchToProps(dispatch) {
    return {
        addToCart: (item) => {
            dispatch({type: 'ADD', payload: item})

        },
        removeFromCart: (item) => {
            dispatch({type: 'REMOVE', payload: item})
        },
        addExtraToCart: (item) => {
            dispatch({type: 'ADD_EXTRA', payload: item})
        },
        removeExtraFromCart: (item) => {
            dispatch({type: 'REMOVE_EXTRA', payload: item})
        },
        removeAllFromCart: (item) => {
            dispatch({type: 'REMOVE_ALL', payload: item})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MobileCart);