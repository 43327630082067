import React from 'react';

import ProductListing from '../features/product-listing';

import data from '../data/products';
import {Main} from '../components/Main';
import {Sidebar} from '../components/Sidebar';
import {Notice} from '../components/Notice';
import MobileCart from '../components/MobileCart';

export default function Homepage(props) {
    return <div className="main">
        <Notice/>
        <ProductListing />
        <MobileCart/>
    </div>;
}