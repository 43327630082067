import {serializeForm} from '../helpers';
import axios from 'axios';
import {loginEndpoint} from '../../endpoints';
import {connect} from 'react-redux';

export function login(e) {
    const data = serializeForm(e.target);
    axios.post(loginEndpoint, data)
        .then((response) => {
            console.log(response);
            // TODO: do it via redux
            document.querySelector('.modal .alert').classList.remove('d-none');
            document.querySelector('.modal .alert').classList.remove('alert-danger');
            document.querySelector('.modal .alert').classList.add('alert-success');
            document.querySelector('.modal .alert').innerHTML = 'Gratulujem! Prebehlo úspešné prihlásenie.';
            // props.addNotify({
            //     message: 'Gratulujem! Prebehla úspešná registrácia.',
            //     color: 'success'
            // });
            localStorage.setItem('token', response.data.access_token);
            setTimeout(() => {
                window.location.reload();
            }, 3000);
        })
        .catch((error) => {
            document.querySelector('.modal .alert').classList.remove('d-none');
            document.querySelector('.modal .alert').classList.remove('alert-success');
            document.querySelector('.modal .alert').classList.add('alert-danger');
            document.querySelector('.modal .alert').innerHTML = 'Nesprávne zadaný email alebo heslo. Zopakuj svoje zadanie, prosím.';
            // props.addNotify({
            //     message: 'Počas registrácie nastala chyba. Prosím, skontroluj si polia.',
            //     color: 'error'
            // });
        })
        .finally(() => {

        });
}

// function mapStateToProps(state) {
//     return {
//         notify: state.notify
//     }
// }
//
// function mapDispatchToProps(dispatch) {
//     return {
//         addNotify: (notify) => {
//             dispatch({type: 'ADD_NOTIFY', payload: notify})
//
//         }
//     }
// }
//
// export default connect(mapStateToProps, mapDispatchToProps)(login);