import React from 'react';
import styled from 'styled-components';
import {Header} from './Header';
import {Sidebar} from './Sidebar';
import Homepage from '../pages/homepage';
import ProductListing from '../features/product-listing';
import data from '../data/products';
import {products} from '../services/api/products';
import Router from '../Router';
import MobileCart from './MobileCart';

const MainWrapper = styled.div`
    position: fixed;
    width: 1140px;
    top: 190px;
    display: block;
    .main {
        width: calc(70% - 60px);
        display: inline-block;
        padding: 0 15px;
        vertical-align: top;
        overflow-y: auto;
        overflow-x: hidden;
        height: calc(100vh - 200px);
    }
    @media screen and (max-width: 991px) {
            width: 100%;
            position: relative;
            top: 0;
            margin-top: 15px;
            margin-bottom: 70px;
           
            .main {
                width: 100%;
                height: auto;
                padding: 0;
            }
        }
`;

export const Main = (props) => {
    return (
        <MainWrapper>
            <Sidebar/>
            <Router/>
        </MainWrapper>
    );
};