import {serializeForm} from '../helpers';
import axios from 'axios';
import {registerEndpoint} from '../../endpoints';

export function register(e) {
    const data = serializeForm(e.target);
    axios.post(registerEndpoint, data)
        .then((response) => {
            localStorage.setItem('token', response.data.access_token);
            document.querySelector('.modal .alert').classList.remove('d-none');
            document.querySelector('.modal .alert').classList.remove('alert-danger');
            document.querySelector('.modal .alert').classList.add('alert-success');
            document.querySelector('.modal .alert').innerHTML = 'Gratulujem! Prebehla úspešná registrácia.';
        })
        .catch((error) => {
            console.error(error);
            document.querySelector('.modal .alert').classList.remove('d-none');
            document.querySelector('.modal .alert').classList.remove('alert-success');
            document.querySelector('.modal .alert').classList.add('alert-danger');
            document.querySelector('.modal .alert').innerHTML = 'Nesprávne zadaný email alebo heslo. Zopakuj svoje zadanie, prosím.';
        })
        .finally(() => {

        });
}