export const brandRed = '#9E2023';
export const brandLightRed = '#e13940';
export const white = '#ffffff';
export const gray = '#eee';
export const black = '#000000';

export const animation = '-moz-transition: all .25s linear;' +
    '  -o-transition: all .25s linear; -ms-transition: all 2.25s linear;' +
    '  -webkit-transition: all .25s linear;' +
    '  transition: all .25s linear;'

export const borderRadius = 'border-radius: 4px;' +
    '-moz-border-radius: 4px;' +
    '-webkit-border-radius: 4px;';

export const cubicTransition  =
    'transition: {' +
    'duration:0;' +
    'property:300ms;'+
    'timing-function: cubic-bezier(.7,1,.7,1);' +
    '}';