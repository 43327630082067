import React, {useState, useEffect} from 'react';

import ProductListing from '../features/product-listing';

import data from '../data/products';
import {NavLink, Redirect} from 'react-router-dom';
import {Input} from '../components/Input';
import Row from 'reactstrap/es/Row';
import Col from 'reactstrap/es/Col';
import {connect} from 'react-redux';
import {
    calculateClosedDoorsAlert,
    changePriceFormat,
    getDeliveryFee,
    headerWithToken,
    shortProductName,
    sort
} from '../services/helpers';
import styled from 'styled-components';
import * as helperConstants from '../helpers/constants';
import {Button} from '../components/Button';
import {deliveryDestinationsEndpoint, getUser} from '../endpoints';
import {register} from '../services/api/register';
import {makeOrder} from '../services/api/order';
import Alert from 'reactstrap/es/Alert';

const OrderWrapper = styled.div`
    a {
        color: ${helperConstants.brandRed};
        text-decoration: underline;
    }
    h5 {
        padding-left: 15px;
        &:not(:first-child) {
            margin-top: 15px;
        }
    }
    i, b.noProducts {
        padding-left: 15px;
    }
    .alertMinimal {
        background: rgba(158,32,35,.5);
        color: ${helperConstants.brandRed};
        font-weight: bold;
        padding: 5px;
        text-align: center;
    }
    .alert {
        position: absolute;
    bottom: 27px;
    width: 90%;
    }
    form {
        padding: 0 15px;
        position:relative;
        .cartProducts {
            padding: 0 15px;
        }
        select {
            display: block;
            width: 90%;
            padding: 7px 15px;
            margin: 10px auto;
            borderRadius: 4px;
            border: 1px solid #000000;
            outline: none;
        }
        button[type="submit"] {
            background: ${helperConstants.brandRed};
            color: ${helperConstants.white};
            width: 320px;
            margin: 0 auto;
            font-weight: bold;
        }
        textarea {
            display: block;
            width: 100%;
            padding: 7px 15px;
            margin: 10px auto;
            borderRadius: 4px;
            border: 1px solid #000000;
            outline: none;
        }
        table {
            width: 100%;
            td.quantity {
                button {
                    border: 1px solid ${helperConstants.brandRed};
                    color: ${helperConstants.brandRed};
                    outline: none;
                    width: 25px;
                    ${helperConstants.animation};
                    &:hover {
                        color: ${helperConstants.white};
                        background: ${helperConstants.brandRed};
                    }
                }
                span {
                    border-top: 1px solid ${helperConstants.brandRed};
                    padding: 1px 5px 4px;
                    border-bottom: 1px solid ${helperConstants.brandRed};
                    height: 28px;
                    display: inline-block;
                    @media screen and (max-width: 991px) {
                   padding: 1px 5px 3.5px;
                   height: 23.5px;
                }
                }
                
            }
            td, th {
                padding: 7px;
                @media screen and (max-width: 991px) {
                    padding: 5px;
                    font-size: 13px;
                    &.quantity {
                        min-width: 100px;
                    }
                    &.price {
                        min-width: 30px;
                    }
                }
            }
            tr {
                &:not(:nth-child(odd)) {
                    background: rgba(255, 255, 255, .3);
                }
            }
        }
        @media screen and (max-width: 991px) {
            padding: 0;
            h5 {
                padding: 0;
            }
            .cartProducts {
                padding: 0;
            }
        }
    }
    @media screen and (max-width: 991px) {
            padding: 30px 0;
            b.noProducts {
                text-align: center;
                padding: 0;
                margin: 0 auto;
            }
        }
`;

function OrderPage(props) {

    const [deliveryCity, setDeliveryCity] = useState('');
    const [deliveryPrice, setDeliveryPrice] = useState(0);
    let sum = 0;

    const [destinationId, setDestinationId] = useState(1);
    const [city, setCity] = useState('Spišská Nová Ves');
    const [cityDifferent, setCityDifferent] = useState('');


    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [telephone, setTelephone] = useState('+421');
    const [email, setEmail] = useState('');
    const [street, setStreet] = useState('');
    const [houseNumber, setHouseNumber] = useState('');
    const [zip, setZip] = useState('');
    const [note, setNote] = useState('');
    const [checked, setChecked] = useState(false);

    const [visible, setVisible] = useState(true);
    const onDismiss = () => setVisible(false);

    const [destinations, setDestinations] = useState([]);
    const [user, setUser] = useState([]);
    useEffect(() => {
            async function fetchData() {
                const res = await fetch(deliveryDestinationsEndpoint);
                res
                    .json()
                    .then(res => {setDestinations(res);})
            }

            if (destinations.length === 0)
                fetchData();

        // async function fetchUser() {
        //     const res = await fetch(getUser, {headers: headerWithToken()});
        //     res
        //         .json()
        //         .then(res => setDestinations(res))
        // }
        //
        // if (user.length === 0)
        //     fetchUser();
         }
    );

    if (!calculateClosedDoorsAlert().open) {
        return <Redirect to='/' />
    }

    return <div className="main">
        <NavLink to="/" className="navigationBack">Späť na výber produktov</NavLink>
        <OrderWrapper>
            <h2 className="text-uppercase text-center">Objednávka</h2>
            <form name="order" onSubmit={(e) => { e.preventDefault(); makeOrder(e); }}>
                <h5>Tvoje údaje</h5>
                <Row>
                    <Col lg={6} md={6} sm={12}>
                        <Input type="text" name="first_name" placeholder="Meno" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                    </Col>
                    <Col lg={6} md={6} sm={12}>
                        <Input type="text" name="last_name" placeholder="Priezvisko" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                    </Col>
                </Row>
                <Row>
                    <Col lg={6} md={6} sm={12}>
                        <Input type="text" name="phone_number" placeholder="Mobil" value="+421" value={telephone} onChange={(e) => setTelephone(e.target.value)} />
                    </Col>
                    <Col lg={6} md={6} sm={12}>
                        <Input type="email" name="email" placeholder="E-mail" value={email} onChange={(e) => setEmail(e.target.value)} />
                    </Col>
                </Row>

                <h5>Adresa doručenia</h5>
                <Row>
                    <Col lg={6} md={6} sm={12}>
                        {/*<Input type="text" name="name" placeholder="Označenie tvojej adresy (napr DOMOV)"/>*/}
                        <Input type="text" name="street_name" placeholder="Ulica" value={street} onChange={(e) => setStreet(e.target.value)} />
                    </Col>
                    <Col lg={6} md={6} sm={12}>
                        <Input type="text" name="house_number" placeholder="Číslo domu" value={houseNumber} onChange={(e) => setHouseNumber(e.target.value)} />
                    </Col>
                </Row>
                <Row>
                    <Col lg={6} md={6} sm={12}>
                        <Input type="text" name="zip" placeholder="PSČ" value={zip} onChange={(e) => setZip(e.target.value)} />
                    </Col>
                    <Col lg={6} md={6} sm={12}>
                        <select onChange={(e) => {setCity(e.target.value); setDestinationId(e.target.childNodes[e.target.selectedIndex].id);console.log(e.target.value)}} value={city}>
                            <option selected disabled>Vyberte si mesto</option>
                            {
                                (destinations.length !== 0) ?
                                destinations[1.5].map((d, key) =>
                                    <option value={d.destination} id={d.id} key={'.5' + key}>{d.destination}</option>
                                )
                                    : ''
                            }
                            {
                                (destinations.length !== 0) ?
                                    destinations[2.5].map((d, key) =>
                                        <option value={d.destination} id={d.id} key={'.7' + key}>{d.destination}</option>
                                    )
                                    : ''
                            }
                            <option value="iné">{(destinations.length !== 0) ? destinations[3.5][0].destination : ''}</option>
                        </select>

                        <input type="hidden" name="delivery_destination_id" value={destinationId} />


                    </Col>


                </Row>


                    {
                        (city === 'iné') ?
                            <Row>
                            <Col lg={6}></Col>
                            <Col lg={6}>
                        <Input type="text" name="city" value={cityDifferent} onChange={(e) => setCityDifferent(e.target.value)} placeholder="Zadajte mesto"/>
                        </Col></Row> : <Input type="hidden" name="city" value={city} placeholder="Zadajte mesto"/>
                    }


                {/*<h5>Zaregistruj sa a využívaj výhody!</h5>*/}
                {/*<i>V prípade, že nevyplníš heslo, odošleš objednávku bez registrácie.</i>*/}
                {/*<Row>*/}
                {/*    <Col lg={6} md={6} sm={12}>*/}
                {/*        <Input type="password" name="password" placeholder="Heslo"/>*/}
                {/*    </Col>*/}
                {/*</Row>*/}

                <hr/>

                <h5>Rekapitulácia objednávky</h5>
                {
                    (props.cart.length > 0) ?
                        <div>
                            <div className="cartProducts">
                                <table>
                                    <thead>
                                    <tr>
                                        <th>#</th>
                                        <th colSpan={2}>Položka</th>
                                        <th>Počet</th>
                                        <th>Cena</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        sort(props.cart).map((item, key) => {
                                                if (item.extraResult.length === undefined) {
                                                    item.extraResult = [item.extraResult];
                                                }

                                                const extraNumber = (item.extraResult.length === 0) ? '' : <p></p>;
                                                const extraName = (item.extraResult.length === 0) ? '' :
                                                    <p>+ {item.extraResult[0].name}</p>;
                                                const extraCount = (item.extraResult.length === 0) ? '' : <p>
                                                    <button type="button"
                                                        onClick={() => props.removeExtraFromCart(item)}
                                                    >-
                                                    </button>
                                                    <span>
                                    {item.extraResult.length}
                                    </span>
                                                    <button type="button"
                                                        onClick={() => props.addExtraToCart(item)}
                                                    >+
                                                    </button>
                                                </p>;
                                                const extraPrice = (item.extraResult.length === 0) ? '' :
                                                    <p>{changePriceFormat(item.extraResult[0].price * item.extraResult.length)} €</p>;

                                            sum += item.price * item.quantity;
                                            if (item.extraResult.length !== 0) {
                                                sum += item.extraResult[0].price * item.extraResult.length;
                                            }

                                                return (
                                                    <tr title={item.name}>
                                                        <td>{key + 1}.{extraNumber}</td>
                                                        <td className="productName" colSpan={2}>
                                                            {shortProductName(item.name)}{extraName}
                                                        </td>
                                                        <td className="quantity">
                                                            <button type="button"
                                                                onClick={() => props.removeFromCart(item)}
                                                            >-
                                                            </button>
                                                            <span>
                                    {item.quantity}
                                    </span>
                                                            <button type="button"
                                                                onClick={() => props.addToCart(item)}
                                                            >+
                                                            </button>
                                                            {extraCount}
                                                        </td>
                                                        <td className="price">{changePriceFormat(item.price * item.quantity)} € {extraPrice}</td>
                                                    </tr>
                                                )

                                            }
                                        )
                                    }
                                    <tr>
                                        <td></td>
                                        <td>Doručenie do <b>{city}</b></td>
                                        <td></td>
                                        <td></td>
                                        <td className="price">{changePriceFormat(getDeliveryFee(city))} €</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td><b>SPOLU</b></td>
                                        <td></td>
                                        <td></td>
                                        <td className="price"><b>{changePriceFormat(sum + getDeliveryFee(city))} €</b></td>
                                    </tr>
                                    </tbody>
                                </table>
                                <textarea name="note" placeholder="Poznámka pre prijímateľa" value={note} onChange={(e) => setNote(e.target.value)}></textarea>
                                <input type="hidden" name="orderData" value={JSON.stringify(props.cart)} />
                                {
                                    (sum < 14.9) ?
                                        <div className="alertMinimal">
                                            Minimálna hodnota objednávky je 14,90 €.
                                        </div> : <div className="text-center mt-4 mb-4">
                                            <Input type="checkbox" name="agree_general_condition" id="agree_general_condition_order" value={checked} checked={checked} onChange={(e) => setChecked(e.target.checked)} label="Súhlasím s <a href='/documents/vop.pdf' target='_blank'>obchodnými podmienkami</a>"/>
                                        <Button type="submit" text="ODOSLAŤ OBJEDNÁVKU" /> <br /> <i>* Aktuálne je možné za objednávku zalpatiť iba v hotovosti.</i>
                                        </div>
                                }
                            </div>
                        </div>
                        :
                        <b className="mt-5 noProducts">V košíku nemáš žiadne produkty. <NavLink to="/">Pridaj si niečo do košíka.</NavLink></b>
                }



                <Alert isOpen={visible} color="success" className="d-none" toggle={onDismiss} closeAriaLabel="X"></Alert>
            </form>
        </OrderWrapper>
    </div>;
}

function mapStateToProps(state) {
    return {
        cart: state.cart
    }
}

function mapDispatchToProps(dispatch) {
    return {
        addToCart: (item) => {
            dispatch({type: 'ADD', payload: item})

        },
        removeFromCart: (item) => {
            dispatch({type: 'REMOVE', payload: item})
        },
        addExtraToCart: (item) => {
            dispatch({type: 'ADD_EXTRA', payload: item})
        },
        removeExtraFromCart: (item) => {
            dispatch({type: 'REMOVE_EXTRA', payload: item})
        },
        removeAllFromCart: (item) => {
            dispatch({type: 'REMOVE_ALL', payload: item})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderPage);