import React from 'react';
import styled from 'styled-components'
import Container from 'reactstrap/es/Container';

const FooterContainter = styled.div`
    display: block;
    background: transparent;
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    .container {
        background: transparent;
        .footer-content {
            padding: 15px;
        }
    }
    `;

export const Footer = () => <FooterContainter>
    <Container>
        <div className='footer-content'>
            KOSIK OBJEDNAVKA
        </div>
    </Container>
</FooterContainter>