const API_URL = 'https://food-shop-backend.toplangose.sk/api/';
const API_VER = 'v1/';
const AUTH = 'auth/';
const PUBLIC = 'public/';
const PRIVATE = 'private/';

export const loginEndpoint = API_URL + API_VER + AUTH + 'login';
export const registerEndpoint = API_URL + API_VER + AUTH + 'register';
export const forgotPasswordEndpoint = API_URL + API_VER + AUTH + 'reset-password';
export const orderEndpoint = API_URL + API_VER + PUBLIC + 'order/create';
export const productsEndpoint = API_URL + API_VER + PUBLIC + 'products';
export const deliveryDestinationsEndpoint = API_URL + API_VER + PUBLIC + 'delivery-destinations';
export const getUser = API_URL + API_VER + PRIVATE + 'user/get';

// TODO: nefunguje getUser, getDestinations